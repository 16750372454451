<template>
  <div>
    <div>
      <div class="inputFrom">
        <div>诉求</div>
        <div>
          <input v-model="idList.Appeal"
            placeholder="请输入诉求" />
        </div>
      </div>
      <div class="inputFrom">
        <div>联系人</div>
        <div>
          <input v-model="idList.TouchMan"
            placeholder="请输入联系人" />
        </div>
      </div>
      <div class="inputFrom">
        <div>联系电话</div>
        <div>
          <input v-model="idList.TouchTel"
            placeholder="请输入联系电话" />
        </div>
      </div>

      <div class="inputFrom">
        <div>地址</div>
        <div>
          <input v-model="idList.Address"
            placeholder="请输入地址" />
        </div>
      </div>
      <div class="inputFrom">
        <div>备注</div>
        <div>
          <input v-model="idList.Remark"
            placeholder="请输入备注" />
        </div>
      </div>
      <div class="fromButton">
        <van-button type="info"
          @click="postfrom">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WxSaveTopics
} from "@/api/yeguang";
import wx from "weixin-js-sdk";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      idList: {
        TId: 0,
        TouchMan: "",
        TouchTel: "",
        Address: "",
        Remark: "",
        Appeal: "",
      },
    };
  },
  created () {
    this.RegionList();
    this.MemberInfo();
  },
  methods: {
    // 提交
    postfrom: function () {
      // 手机号验证
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.idList.Appeal == "") {
        Toast.fail("请输入诉求");
        return false;
      }
      if (this.idList.TouchMan == "") {
        Toast.fail("请输入联系人");
        return false;
      }

      if (this.idList.TouchTel == "") {
        Toast.fail("请输入联系电话");
        return false;
      } else {
        if (!reg_tel.test(this.idList.TouchTel)) {
          Toast.fail("请输入正确的联系电话");
          return false;
        }
      }

      this.idList.OpenID = getOpenId()
      WxSaveTopics(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            // this.$router.go(-1)

            if (window.__wxjs_environment === "miniprogram") {
              window.wx.miniProgram.switchTab({
                url: "../home/index",
              });
            } else {
              this.$router.push({
                name: "Home",
              });
            }
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 小区列表
    RegionList: function () {
      WeGetRegionList({ organCode: "4103110201" }).then((res) => {
        this.ridlist = res.data.data;
      });
      GetCodeEntrysList({ kind: 14 }).then((res) => {
        //户主关系
        this.guanxi = res.data.data;
      });
      GetCodeEntrysList({ kind: 3 }).then((res) => {
        //民族
        this.minzu = res.data.data;
      });
      GetCodeEntrysList({ kind: 6 }).then((res) => {
        //政治面貌
        this.zhengzhi = res.data.data;
      });
      GetCodeEntrysList({ kind: 5 }).then((res) => {
        //学历
        this.xveli = res.data.data;
      });
      GetCodeEntrysList({ kind: 13 }).then((res) => {
        //户口状态
        this.zhuangtai = res.data.data;
      });
      GetCodeEntrysList({ kind: 4 }).then((res) => {
        //婚姻
        this.hunyin = res.data.data;
      });
      // 地区
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [],
          Citysx = [],
          Citysq = [],
          Citysqp = [],
          list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                text: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, text: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, text: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
        console.log(this.NativeCantonCodedata);
        this.NativeCantonCodedataCs = Citys;
      });
    },
    // 楼栋列表
    BuildList: function (val) {
      WeGetBuildList({ rId: val }).then((res) => {
        this.BIdlist = res.data.data;
      });
    },
    // 单元列表
    BuildUnitList: function (val) {
      WeGetBuildUnitList({ bId: val }).then((res) => {
        this.BUIdlist = res.data.data;
      });
    },
    // 楼层列表
    BuildFloorList: function (val) {
      WeGetBuildFloorList({ bId: this.idList.BId, buId: val }).then((res) => {
        this.BFIdlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 小区选择器
    onidListRId (val) {
      this.idList.RId = val.RId;
      this.idList.RIdTitle = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 楼栋选择器
    onidListBId (val) {
      this.idList.BId = val.BId;
      this.idList.BIdTitle = val.Title;
      this.idListBId = false;
      this.BuildUnitList(val.BId);
    },
    // 单元选择器
    onidListBUId (val) {
      this.idList.BUId = val.BUId;
      this.idList.BUIdTitle = val.Title;
      this.idListBUId = false;
      this.BuildFloorList(val.BUId);
    },
    // 单元选择器
    onidListBFId (val) {
      this.idList.BFId = val.BFId;
      this.idList.BFIdTitle = val.FloorNo;
      this.idListBFId = false;
      this.BuildRoomList(val.BFId);
    },
    // 房屋选择器
    onidListBRId (val) {
      this.idList.BRId = val.BRId;
      this.idList.BRIdTitle = val.RoomNO;
      this.idListBRId = false;
    },
    // 民族选择器
    onNationshow (val) {
      this.idList.NationCode = val.Coding;
      this.idList.NationName = val.Name;
      this.Nationshow = false;
    },
    // 户口状态
    onHouserStatus (val) {
      this.idList.HouserStatusCode = val.Coding;
      this.idList.HouserStatusName = val.Name;
      this.HouserStatus = false;
    },
    // 户口关系
    onRegisterKind (val) {
      this.idList.RelationHouserCode = val.Coding;
      this.idList.RelationHouserName = val.Name;
      this.RegisterKind = false;
    },
    // 婚姻
    onMarriage (val) {
      this.idList.MarriageCode = val.Coding;
      this.idList.MarriageName = val.Name;
      this.Marriage = false;
    },
    // 学历
    onEducation (val) {
      this.idList.EducationCode = val.Coding;
      this.idList.EducationName = val.Name;
      this.Education = false;
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value);
      this.idList.NativeCantonCode = RegCity[0];
      this.idList.NativeCantonName = RegCity[1];
      this.LiveCity = false;
    },
    onPolitical (val) {
      this.idList.PoliticalCode = val.Coding;
      this.idList.PoliticalName = val.Name;
      this.Political = false;
    },
    addressfrom (value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
    // 按钮
    dengji () {
      if (this.idList.RIdTitle == undefined) {
        Toast.fail("请选择所住小区");
        return false;
      }
      if (this.idList.BIdTitle == undefined) {
        Toast.fail("请选择所住楼栋");
        return false;
      }
      if (this.idList.BUIdTitle == undefined) {
        Toast.fail("请选择所住单元");
        return false;
      }
      if (this.idList.BFIdTitle == undefined) {
        Toast.fail("请选择所住楼层");
        return false;
      }
      if (this.idList.RIdTitle == undefined) {
        Toast.fail("请选择所住房号");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      this.addshow = true;
      this.verifyshow = false;
    },
    pageshow () {
      this.addshow = false;
      this.verifyshow = true;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>